import React from "react";
import {
  InstantSearch,
  Configure,
  useHits,
} from "react-instantsearch-hooks-web";
import { Slider } from "app/components/Slider";
import ClassCard from "app/components/ClassCard";
import { ClassCardSkeleton } from "app/components/ClassCard/ClassCardSkeleton";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { searchClient } from "app/components/Algolia/constants";
import { Routes } from "constants/routes";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { useAccessTypeFilterForUser } from "app/components/Algolia/hooks";
import { useGetUserLevel } from "./hooks";
import { Section } from "../Section";

export function TrendingClassesWithLevel() {
  const { level: userLevel } = useGetUserLevel();
  const accessTypeFilterForUser = useAccessTypeFilterForUser();
  const accessFacetFilter = accessTypeFilterForUser.map(
    accessType => `accessType:${accessType}`
  );

  if (!userLevel) {
    return <TrendingClasses accessFacetFilter={accessFacetFilter} />;
  }

  return (
    <InstantSearch
      indexName={AlgoliaIndexes.classesByTrendingRankingAsc}
      searchClient={searchClient}
    >
      <Configure
        facetFilters={[`level:${userLevel}`, accessFacetFilter]}
        hitsPerPage={10}
      />
      <Section
        title={`Trending ${userLevel} Classes`}
        headerLink={{
          route: `${Routes.results__classes}?sortBy=${AlgoliaIndexes.classesByTrendingRankingAsc}&refinementList[level][0]=${userLevel}`,
          text: "See All",
        }}
      >
        <ClassHits />
      </Section>
    </InstantSearch>
  );
}

function TrendingClasses({
  accessFacetFilter,
}: {
  accessFacetFilter: string[];
}) {
  return (
    <InstantSearch
      indexName={AlgoliaIndexes.classesByTrendingRankingAsc}
      searchClient={searchClient}
    >
      <Configure facetFilters={[accessFacetFilter]} hitsPerPage={10} />
      <Section
        title="Trending Classes"
        headerLink={{
          route: `${Routes.results__classes}?sortBy=${AlgoliaIndexes.classesByTrendingRankingAsc}`,
          text: "See All",
        }}
      >
        <ClassHits />
      </Section>
    </InstantSearch>
  );
}

function LoadingSlider() {
  return (
    <Slider
      data={[{}, {}, {}, {}, {}]}
      slideComponent={ClassCardSkeleton}
      slidesSpacing={24}
      cardHeight="190px"
    />
  );
}

function ClassHits() {
  const { hits } = useHits();
  if (!hits) {
    return <LoadingSlider />;
  }

  if (hits.length <= 0) {
    return null;
  }

  return (
    <Slider
      data={hits}
      slideComponent={(props: any) => (
        <ClassCard
          {...props}
          container={HomeSelectContentContainer.TrendingClassesLevel}
          selectedFrom="TrendingClasses"
        />
      )}
      slidesSpacing={24}
      cardHeight="190px"
    />
  );
}
