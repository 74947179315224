import { useSelector } from "react-redux";

enum ClassLevel {
  Beginner = "Beginner",
  Intermediate = "Intermediate",
  Advanced = "Advanced",
}

const LevelPreferenceMappings = {
  "brand-new": ClassLevel.Beginner,
  beginner: ClassLevel.Beginner,
  intermediate: ClassLevel.Intermediate,
  advanced: ClassLevel.Advanced,
};

export function useGetUserLevel() {
  const level = useSelector(
    ({ user }: { user: any }) => user.private?.dance_preferences?.level
  ) as keyof typeof LevelPreferenceMappings;

  if (!level) {
    return { level: null };
  }

  return {
    level: LevelPreferenceMappings[level],
  };
}
